.request-container {
    padding: 10px 30px 10px 30px;
    justify-content: space-between;
    width: 100%;
}

.requests-list-container {
    margin-top: 30px;
}

.request-item {
    background-color: #E5E5E5;
}