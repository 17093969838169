.project-selector {
    width: auto;
}
  
.project-container {
    justify-content: space-between;
}
  
.projects-table-header {
    justify-content: space-between;
}
  
.projects-list {
    margin-top: 10px;
}
  
.project-list-item {
    background-color: #E5E5E5;
}
  
.project-route {
    color: #777777;
}

.button-edit {
    margin-left: 40px;
}