.form-register {
    width: 50%;
    margin: 50px auto;
}

.center-aligned {
    text-align: center;
}

.button-register {
    width: 50%;
    margin-top: 40px;
}

.password, .confirm-password {
    margin-top: 10px;
}

.link-login {
    text-decoration: none;
}

.login-holder {
    margin-top: 20px;
}

.warning {
    color: orangered;
}