.content-table {
    margin: 80px 80px 40px 80px;
}

.vertical-center {
    align-items: center;
}
  
.flex{
    display: flex;
}