.input {
    width: 300px;
    margin: 10px;
}

.app-info {
    margin-left: 30px;
}

.button-save {
    background-color: #28A745;
    height: min-content;
}

.project-holder {
    margin-top: 20px;
    background-color: #E5E5E5;
    display: table;
    width: 100%;
}

.project-holder-invisible {
    margin-top: 20px;
    background-color: #C4C4C4E5;
    display: table;
    width: 100%;
}

.ads-item-img {
    height: 200px;
    width: 200px;
}

.checkbox-visibility {
    margin-left: 10px;
}

#input-project-title {
    margin-left: 0px;
}

.button-add-app {
    background-color: #28A745;
    margin-bottom: 80px;
    margin-top: 20px;
}

.button-remove {
    height: min-content;
}

.project-control-panel {
    width: min-content;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 10px;
}

.drag-image-holder {
    margin-top: 30px;
    align-content: center;
}

.image-drag {
    display: block;
    margin-left: auto;
    margin-right: auto;
}