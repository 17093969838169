.header {
  padding: 10px 80px;
}

.logo {
  margin-right: 10px;
}

.navbar-button {
  margin-left: 20px;
}

.button-active > button {
  background-color: #28A745;
  color: #FFFFFF;
}

.button-logout {
  margin-left: auto;
}

.navigation {
  width: max-content;
}